<template>
  <NForm
    inline
    label-placement="left"
  >
    <NFormItem label="搜索关键词：">
      <NInput
        placeholder="请输入教材名称/ID"
        maxlength="50"
        show-count
        clearable
        v-model:value="formValue.title"
      />
    </NFormItem>
    <NFormItem>
      <NSpace>
        <NButton type="primary" @click="handleSearch">搜索</NButton>
        <NButton @click="handleClear">清除</NButton>
      </NSpace>
    </NFormItem>
  </NForm>
</template>

<script setup>
  import { reactive } from 'vue';

  const getDefaultFormValue = () => ({
    title: ''
  });
  const formValue = reactive(getDefaultFormValue());
  const getSearchParams = () => {
    const params = {};
    Object.keys(formValue).forEach(key => {
      const value = formValue[key];
      value && (params[key] = value);
    });
    return params;
  };

  const emit = defineEmits(['list-update']);
  const handleSearch = () => {
    emit('list-update');
  };
  const handleClear = () => {
    Object.assign(formValue, getDefaultFormValue());
    handleSearch();
  };

  defineExpose({
    getSearchParams
  });
</script>