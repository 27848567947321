import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { POST } = reqMethodEnum;

export function formDataUploadFile(url, data) {
  if (FormData) {
    const reqData = new FormData();
    Object.keys(data).forEach(key => {
      const value = data[key];
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          reqData.append(`${ key }[${ index }]`, item);
        });
      } else {
        reqData.append(key, value);
      }
    });
    
    return request({
      url,
      method: POST,
      data: reqData,
      config: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } else {
    return Promise.reject('不支持文件上传');
  }
}